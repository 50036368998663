button {
  border: none;
  outline: none;
  background-color: transparent;
}

* {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  padding: 0;
  scrollbar-width: thin !important;
  text-decoration: none;
}

@media (max-width: 768px) {
  * {
    font-size: 13px;
  }
}

@media (max-width: 425px) {
  * {
    font-size: 12px;
  }
}

html,
body {
  background-color: #f9f9f9 !important;
  color: #222222;
}

.text-danger {
  font-size: 0.7em;
}

/* CHROME SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}

.MuiButton-root {
  text-transform: none !important;
}

h1,
h2,
p,
span {
  margin: 0;
}

a {
  color: #4E84F9;
}

